.am-list-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 15px;
    min-height: 44px;
    background-color: rgba(0, 0, 0, 0);
    vertical-align: middle;
    overflow: hidden;
    -webkit-transition: background-color 200ms;
    transition: background-color 200ms;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    /* list左图片显示*/
  }